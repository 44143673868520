@import 'styles/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
}

.progress {
  margin-left: 28px;
  display: flex;
  gap: 40px;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.viewedWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.active {
  color: var(--color-success);
}
.inactive {
  color: var(--color-placeholder-25);
}

.receiver {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}

.table {
  :global {
    .ant-table-cell-row-hover {
      cursor: pointer;
    }

    .ant-table-cell {
      font-size: 12px !important;
      font-weight: 500 !important;
    }
  }
}

.message {
  @include ellipsis-text;

  -webkit-line-clamp: 1;
  line-clamp: 1;
}
