.container {
  border-top: 1px solid var(--color-neutral-5);
  border-bottom: 1px solid var(--color-neutral-5);
  background: var(--color-neutral-2);
  padding: 8px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchContainer {
  .input {
    width: 264px;
    height: 32px;
  }
  .select {
    width: 364px;
    height: 32px;
  }
}
