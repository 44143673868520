.tabs {
  height: 100%;
  overflow: hidden;

  :global {
    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}

.form {
  overflow: auto;
}
