.wrapper {
  > :first-child {
    width: 424px;
  }

  > :last-child {
    width: 160px;
  }

  :global {
    .ant-picker {
      width: 100%;
    }
  }
}
