.body {
  margin-top: 16px;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 32px;
}

.docks {
  > div {
    flex: 1;
  }

  :global {
    .ant-picker {
      width: 100%;
    }
  }
}

.noSpinners {
  -moz-appearance: textfield;
}

.noSpinners::-webkit-outer-spin-button,
.noSpinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.actions {
  padding-top: 16px;
}
