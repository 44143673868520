.wrapper {
  :global {
    .ant-switch {
      width: max-content;
    }
  }
}

.additional {
  padding-left: 12px;
  border-left: 2px solid var(--color-primary-6);

  input {
    width: 260px !important;
  }
}
