.title {
  margin-top: 8px;
}

.tooltip {
  :global {
    .ant-tooltip-content {
      width: 440px;
    }
  }
}
