.formActionsWrapper {
  max-width: 600px;
}

.fullWidth {
  width: 100%;
}

.formWrapper {
  padding: 14px 16px;
}
