@import 'src/styles/mixins.scss';

// QA НАНЫЛИ
.classNameForMobileCollapsed {
  @include tablet {
    padding: 0 0 4px !important;
    overflow: auto !important;
    height: fit-content !important;
    flex-direction: row !important;
    width: 100% !important;

    button {
      flex-shrink: 0 !important;
    }
  }
}
