.tableRowContainer {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    color: var(--color-primary-6);

    svg {
      height: 18px;
      width: 18px;

      path {
        fill: var(--color-primary-6);
      }
    }
  }
}
