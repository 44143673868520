.emptyContainer {
  margin: 80px 0;

  .icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: var(--color-primary-6);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: var(--color-neutral-1);
    }
  }
}
