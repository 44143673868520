.contentLna {
  width: 100%;
  padding: 24px 16px 0;
}

.contentSettings {
  width: 616px;
  padding: 24px 16px 0;
}

.content {
  width: 100%;
  overflow-y: auto;
}

.actions {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 24px 16px;
  width: 924px;
}

.actionsHistory {
  width: 616px;
}
