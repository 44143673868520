.container {
  display: grid;
  border-top: 1px solid var(--color-neutral-3);

  .buttonWrapper {
    width: 100%;
    border-bottom: 1px solid var(--color-neutral-4);
  }

  .button {
    height: max-content !important;
    color: var(--color-primary-6) !important;
    padding: 16px !important;

    &:hover {
      background-color: transparent;
    }

    path {
      fill: var(--color-primary-6);
    }
  }
}

.backButton {
  max-width: 296px;
  margin: 16px;
}
