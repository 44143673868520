.table {
  :global {
    .ant-table-cell {
      height: 54px;
    }

    .ant-table-cell-row-hover {
      cursor: pointer;

      span {
        color: var(--color-primary-6) !important;
      }
    }
  }
}
