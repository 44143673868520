.container {
  border-top: 1px solid var(--color-neutral-5);
  border-bottom: 1px solid var(--color-neutral-5);
  background: var(--color-neutral-2);
  padding: 8px 16px;

  :global {
    .ant-input-affix-wrapper {
      width: 264px;
    }

    .ant-btn {
      height: 32px !important;
    }
  }
}
