.item {
  margin-bottom: 24px;

  > :first-child {
    width: 100%;
  }

  :global {
    .ant-form-item {
      margin: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .button {
    padding: 0 !important;
    font-size: 14px !important;
    justify-self: flex-start !important;
    height: max-content !important;
  }

  .option {
    background: transparent !important;
    :global {
      .ant-select-item-option-state {
        display: none;
      }
      .ant-select-selection-item {
        background: var(--color-primary-6) !important;
      }
    }
  }
}
