.container {
  max-width: 600px !important;

  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}

.notification {
  margin-left: 24px !important;
}
