.form {
  max-width: 600px;

  :global {
    .ant-form-item-has-error .ant-input {
      border-color: var(--color-error);
    }
    .ant-form-item-explain-error {
      margin-top: 8px;
    }
  }
}
