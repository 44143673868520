.container {
  display: flex;
  gap: 32px;
  padding: 15px 18px;
  cursor: pointer;
  background: var(--color-neutral-2);
  border-top: 1px solid var(--color-neutral-5);
  border-bottom: 1px solid var(--color-neutral-5);

  > button {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
  }
}
