.wrapper {
  height: 42px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  gap: 24px;

  > :last-child {
    margin-left: 4px;
    margin-top: 6px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}

.loader {
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  border: 3px solid transparent;
  border-top: 3px solid var(--color-primary-6);
  border-bottom: 3px solid var(--color-primary-6);
  border-right: 3px solid var(--color-primary-6);
}

.progress {
  margin-top: -4px;
}
