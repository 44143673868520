.goBack {
  width: fit-content;
  padding-inline-start: 0 !important;
  justify-content: flex-start !important;
}

.description {
  display: flex;
  gap: 4px;
}

.edit {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  path {
    margin-top: 2px;
    fill: var(--color-primary-6);
  }
}
