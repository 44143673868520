.wrapper {
  width: 70px;
  height: 70px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.noData {
  background: var(--color-primary-6);
}
