.create {
  background: var(--color-neutral-2);
  border-top: 1px solid var(--color-neutral-4);
  border-bottom: 1px solid var(--color-neutral-4);
  padding: 7px 0;
  margin-top: 16px;

  .button {
    font-size: 12px;
  }
}

.withPadding {
  padding: 0 16px;
}

.title {
  margin-bottom: 8px !important;
}
