.content {
  width: 100%;
  overflow-y: auto;
}

.deletedIcon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--color-error);

  path {
    fill: var(--color-neutral-1);
  }
}

.actions {
  padding: 16px 16px 0;
}

.saveButton {
  width: 296px;
}
