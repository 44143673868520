.preview {
  > :first-child {
    > svg {
      color: var(--color-error);
    }
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    gap: 5px;

    path {
      fill: var(--color-character-secondary-45);
    }
  }

  .download {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--color-primary-6);
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    margin-bottom: 8px;
    margin-top: 16px;

    path {
      fill: var(--color-primary-6);
    }
  }
}

.container {
  .error {
    button {
      border-color: blue !important;
    }
  }
}

.dragger {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 24px;
  padding: 0 16px;

  > :first-child {
    height: 24px;
    width: 24px;
    color: var(--color-primary-6);
  }

  > :last-child {
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border: none;

    path {
      fill: var(--color-primary-6);
    }
  }

  .typoContainer {
    display: grid;
    width: 400px;

    & > span {
      width: 400px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      text-align: start;
    }
  }
}

.tooltipIcon {
  margin-top: 4px;
  border: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
}

.tooltip {
  :global {
    .ant-tooltip-content {
      width: 360px;
      padding: 2px 8px;
    }
  }
}

.tooltipTitle {
  color: var(--color-neutral-1) !important;
}
