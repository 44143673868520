@import 'styles/mixins.scss';

.message {
  @include ellipsis-text;

  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.receiver {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}

.active {
  color: var(--color-success);
}

.inactive {
  color: var(--color-placeholder-25);
}

.table {
  :global {
    .ant-table-placeholder .ant-table-cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
