.wrapper {
  display: flex;
  flex-direction: column;
}

.headerWrapper {
  padding: 12px 16px;
}

.headerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.headerListInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--color-primary-6);
}

.headerListInfoTitle {
  color: var(--color-primary-6);
}

.actionsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 12px 16px;

  > :first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    button {
      height: 18px !important;
      padding: 0 !important;
      margin-top: 4px;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
}

.content {
  padding: 0 0 24px 16px;
  width: 616px;
}

.assignmentsActions {
  padding: 16px;
  width: 330px;
}

.appointButton {
  background-color: var(--color-success);
}

.cancelAssignButton {
  background-color: var(--color-error);
}

.closeButton {
  width: 50%;
}
