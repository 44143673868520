.imageWrapper {
  display: flex;
  align-items: center;
  gap: 12px;

  img,
  svg {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    color: var(--color-primary-6);
  }
}

.table {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
