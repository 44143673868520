.logoWrapper {
  padding: 16px;
  min-height: 100vh;
  justify-content: space-around;

  &:after {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 + h4 {
      margin-bottom: 20px !important;
    }

    h3,
    h4 {
      text-align: center;
    }

    h3 {
      margin: 16px 0 12px !important;
    }
  }
}

.actionsWrapper {
  width: 100%;
}

.iconWrapper {
  width: 64px;
  height: 64px;
  background: var(--color-warning);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.warning {
    background: var(--color-warning);
  }

  &.danger {
    background: var(--color-error);
  }

  svg {
    height: 48px;
    width: 48px;
    color: var(--color-neutral-1);
  }
}

.supportWrapper {
  margin-top: 32px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.support {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  background: var(--color-neutral-1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

  > div {
    h4 {
      text-align: left;
    }
  }
}

.supportIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: var(--color-primary-6);
  border-radius: 50%;

  svg {
    height: 24px;
    width: 24px;
    color: var(--color-neutral-1);
  }
}

.secondText {
  padding-top: 32px;
}

.loginButton {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-primary-6);
  background: transparent;
  border: none;
}
