.table {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.communityCover {
  height: 48px !important;
  width: 48px !important;
  border-radius: 50% !important;
}

.communityCategoryTag {
  margin: 0px;
}

.countMembers {
  color: var(--color-primary);
}
