.searchContainer {
  height: 48px;
  background-color: var(--color-neutral-2);
  padding: 0 16px;

  .search {
    max-width: 264px;
    max-height: 32px;
  }

  .actionButtons {
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

.table {
  margin-top: 0;
}

.counter {
  text-wrap: nowrap;
}
