.searchWrapper {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 8px 16px;
  border-top: 1px solid var(--color-neutral-5);
  border-bottom: 1px solid var(--color-neutral-5);
  background: var(--color-neutral-2);

  > :first-child {
    width: 264px;

    svg {
      color: var(--color-character-secondary-45);
      margin-right: 6px;
    }
  }

  button {
    border: none;

    svg {
      height: 16px;
      width: 16px;
    }

    > span {
      font-size: 12px;
    }
  }
}
