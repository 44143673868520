.table {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.nameWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.icon {
  color: var(--color-primary-6);
}

.transports {
  display: flex;
  align-items: center;
  gap: 4px;
}

.stats {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    margin-bottom: 2px;
    height: 18px;
    width: 18px;
  }
}
