.button {
  height: 32px !important;
  border-color: var(--color-neutral-5) !important;
}

.overlayClassName {
  width: 400px;

  > div > div {
    > :first-child {
      :global {
        .ant-collapse-header {
          border-top-left-radius: 12px !important;
          border-top-right-radius: 12px !important;
        }
      }
    }
  }
}
