.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  label {
    word-break: break-all;
  }

  > :last-child {
    width: 100%;
    max-height: 200px;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
  }

  :global {
    .ant-input-affix-wrapper {
      width: calc(100% - 32px);
      color: var(--color-character-secondary-45);
    }
  }
}

.padding {
  > :last-child {
    padding: 0 16px;
  }
}
