.wrapper {
  color: var(--color-character-secondary-45);
  height: 24px !important;

  span {
    font-weight: 400;
  }

  svg {
    height: 12px;
    width: 12px;
  }

  &:hover {
    color: var(--color-primary-6) !important;
  }
}
