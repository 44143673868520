.table {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
