.pie {
  max-width: 344px;
  min-width: 320px;
}

.common {
  padding: 12px;
  background: var(--color-neutral-2);
  border-radius: 20px;
}

.collapse {
  :global {
    .ant-collapse-content-box {
      background: var(--color-neutral-1) !important;
    }
  }
}
