.wrapper {
  :global {
    .ant-tabs {
      overflow: hidden;
    }

    .ant-tabs-tabpane {
      overflow: hidden;
    }

    .ant-tabs-content-holder {
      height: 100%;
      overflow: auto;
    }
  }
}

.actionsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 12px 16px;

  > :first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    button {
      height: 18px !important;
      padding: 0 !important;
      margin-top: 4px;
    }
  }
}

.form {
  width: 600px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
  width: 936px;
}

.content {
  padding: 24px 0 24px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popover {
  border-radius: 0 16px 16px 16px;
  border: 1px solid var(--color-primary-5);
  background: var(--color-neutral-1) !important;

  :global {
    .ant-popover-inner {
      border-radius: 16px !important;
    }
  }
}

.popoverContent {
  position: relative;
  display: flex;
  width: 384px;
  padding: 16px;
  border-radius: 0 20px 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--color-neutral-1);
  text-align: left;

  button {
    top: 8px;
    right: 8px;
    position: absolute;
    border: none;
    background: transparent;
    cursor: pointer;

    svg {
      height: 18px;
      width: 18px;
      color: var(--color-primary-6);
    }
  }
}
