.wrapper {
  padding-top: 16px;
}

.download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  border-radius: 8px;
  border: 1px solid var(--color-neutral-5);
  gap: 12px;
  padding: 12px 16px;

  > div {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.fileDescription {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 448px;
  overflow: hidden;

  > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.icon {
  margin: 12px;
  color: var(--color-primary-6);
  height: 24px;
  width: 24px;
}
