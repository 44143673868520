.headerContainer {
  display: grid;
  grid-template-columns: 420px max-content;

  .headerIconsContainer {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 16px;

    button {
      background-color: transparent;
      border: none;
      outline: none;
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      color: var(--color-primary-6);

      path {
        fill: var(--color-primary-6);
      }

      &.remove {
        color: var(--color-error);

        path {
          fill: var(--color-error);
        }
      }
    }
  }
}

.collapseBody {
  display: grid;
  gap: 16px;
}
