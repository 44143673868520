.wrapper {
  row-gap: 8px !important;
  flex-shrink: 0;
  background: var(--color-neutral-2);
  padding: 4px 16px;
  border-bottom: 1px solid var(--color-neutral-5);
  border-top: 1px solid var(--color-neutral-5);

  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}
