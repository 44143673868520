.modal {
  h4 {
    align-self: flex-start;
    margin-bottom: 28px !important;
  }

  form {
    width: 100%;

    label {
      width: 100%;
      &::after {
        display: none;
      }
    }

    text-align: start;
  }
}
