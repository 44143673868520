.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid var(--color-neutral-3);
}

.formItemLabel {
  margin-top: 16px;
}

.preview {
  > :first-child {
    > svg {
      color: var(--color-primary-6);
    }
  }
}

.tooltip {
  :global {
    .ant-tooltip-content {
      width: 440px;
    }
  }
}
