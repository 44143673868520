.modal {
  span {
    width: max-content !important;
  }

  :global {
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      text-align: start;
    }
  }
}
