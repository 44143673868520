.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.edit {
  padding-top: 2px;
  margin-left: 8px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    margin-top: 2px;
    color: var(--color-primary-6);
  }
}
