.tableWrapper {
  overflow: auto;
  height: 100%;

  :global {
    .ant-table-row {
      cursor: pointer;
    }
  }
}
