.content {
  > div {
    width: 616px;
  }

  padding: 0 16px 16px;
  overflow: auto;
}

.actions {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
}
