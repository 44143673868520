.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px 8px;

  svg {
    height: 14px;
    width: 14px;
  }
}
