.tooltip {
  :global {
    .ant-tooltip-open {
      color: var(--color-primary-6);
    }
  }

  button {
    display: flex;
    align-content: center;
    justify-content: center;
    border: none;
    background: transparent;
    color: var(--color-character-secondary-45);

    &:hover {
      color: var(--color-primary-6);
    }
  }
}

.tooltipRoot {
  :global {
    .ant-tooltip-inner {
      width: 360px;
      font-size: 12px;
    }
  }
}

.inputImitation {
  textarea {
    height: 40px !important;
  }

  :global {
    .ant-input {
      padding: 9px 11px !important;
    }
  }
}
