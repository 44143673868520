.table {
  :global {
    .ant-table-tbody .ant-table-cell {
      cursor: pointer;
    }
  }
}

.emptyContent {
  padding: 80px 0;
}
