.wrapper {
  margin-bottom: 24px;
  :global {
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
}

.modalOpenButton {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    > * {
      color: var(--color-primary-5);
    }
  }

  > * {
    color: var(--color-primary-6);
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  gap: 12px;
}

.categoryInput {
  margin-top: 24px;
}
