.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background-color: var(--color-success);
  border-radius: 50%;
  color: var(--color-neutral-1);
}

.red {
  background-color: var(--color-error);
}
