.select {
  width: 100%;
  text-align: left;
}

.form {
  margin-top: 20px;
  width: 100%;

  > div {
    width: 100%;
  }

  :global {
    .ant-picker {
      width: 100%;
    }
  }
}
