.container {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;

  .resetButton {
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border: none;

    path {
      fill: var(--color-primary-6);
    }
  }
}

.errorStatus {
  :global .ant-upload.ant-upload-drag {
    border: 1px solid var(--color-error) !important;
  }
}

.wrapper {
  > div {
    height: 72px !important;

    > span {
      height: 72px !important;
    }
  }
}
