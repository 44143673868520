.header {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  justify-content: space-between;

  button {
    background: transparent;
    cursor: pointer;
    border: none;
  }

  svg {
    path {
      fill: var(--color-primary-6);
    }
  }
}
