.wrapper {
  margin: 16px 0 24px;
}

.noStyle {
  margin: 0;
}

.container {
  display: flex;
  justify-content: space-between;

  .resetButton {
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border: none;

    path {
      fill: var(--color-primary-6);
    }
  }

  &.error {
    svg {
      color: var(--color-error);
    }

    .title {
      color: var(--color-error);
    }
  }
}

.errorStatus {
  :global {
    .ant-upload.ant-upload-drag {
      border: 1px solid var(--color-error);
    }
  }
}

.dragger {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 16px;

  > :first-child {
    height: 24px;
    width: 24px;
    color: var(--color-primary-6);
  }

  > :last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
