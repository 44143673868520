.wrapper {
  padding: 14px 16px;
  max-width: 600px;
}

.noFormItemMargin {
  div:not(:last-child) {
    margin-bottom: 0px !important;
  }
}
