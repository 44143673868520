.wrapper {
  :global {
    .ant-modal-body {
      align-items: flex-start;
    }
    .ant-typography {
      margin-bottom: 16px !important;
    }
  }
}
