.columnButton {
  cursor: pointer;

  color: var(--color-primary-6);

  path {
    fill: var(--color-primary-6);
  }
}

.nameColumn {
  max-width: 450px;
}

.popover {
  border-radius: 0 16px 16px 16px;
  border: 1px solid var(--color-primary-5);
  background: var(--color-neutral-1) !important;

  :global {
    .ant-popover-inner {
      border-radius: 16px !important;
    }
  }
}

.popoverContent {
  position: relative;
  display: flex;
  width: 384px;
  padding: 16px;
  border-radius: 0 20px 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--color-neutral-1);
  text-align: left;

  button {
    top: 8px;
    right: 8px;
    position: absolute;
    border: none;
    background: transparent;
    cursor: pointer;

    svg {
      height: 18px;
      width: 18px;
      color: var(--color-primary-6);
    }
  }
}

.tabs {
  height: 100%;
  overflow: hidden;

  :global {
    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}
