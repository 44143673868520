.table {
  border-top: 1px solid #f0f0f0;

  :global {
    .ant-table-tbody .ant-table-cell {
      cursor: pointer;
    }
  }
}

.emptyContent {
  padding: 80px 0;
}
