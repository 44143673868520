.wrapper {
  padding: 16px 16px 0;
  width: 616px;

  strong {
    display: flex;
    margin-bottom: 8px;
  }
}

.switchFormItem {
  height: 44px;
  margin-bottom: 16px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.timezoneFormItem {
  width: 100%;

  :global {
    .ant-form-item {
      width: 100%;
    }
  }
}
