.container {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 10px;
  align-items: center;

  .info {
    display: grid;
  }

  .avatar span {
    font-size: 18px !important;
  }

  .position {
    max-width: 180px;
  }

  .department {
    max-width: 180px;
  }

  .name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
