.buttons {
  display: flex;
  gap: 8px;

  .delete {
    background: var(--color-error);

    &:hover {
      background-color: var(--color-error);
    }
  }
}
