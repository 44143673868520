.publish {
  color: var(--color-success);

  &:hover {
    color: var(--color-success) !important;
    opacity: 0.7;
  }
}

.hidden {
  display: none;
}
