.table {
  margin-top: 0;

  tr {
    cursor: pointer;

    > :first-child {
      padding-left: 16px !important;
    }
  }

  .newsCell {
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;

    :global {
      .ant-tag {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 250px;
      }
    }
  }
}
