.phoneMailWrapper {
  max-width: 80%;
}

.tag {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 10px 7px;
  margin: 0px 0px 5px 0px;
}

.marginTop {
  margin-top: 15px;
}
