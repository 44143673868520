.wrapper {
  display: flex;
  align-items: center;
  padding-left: 32px;
  gap: 24px;
  width: 100%;
  border: 1px dashed var(--color-neutral-5);
  border-radius: 8px;
  background: var(--color-neutral-2);
  height: 72px;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
    color: var(--color-primary-6);
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
