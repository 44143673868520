.tag {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 10px;
  color: var(--color-neutral-1);
  width: fit-content;
  border-radius: 20px;
  gap: 4px;
  white-space: nowrap;

  svg {
    height: 16px;
    width: 16px;
    color: var(--color-neutral-1);
  }
}

.warning {
  background: var(--color-warning);
}

.error {
  background: var(--color-error);
}

.continue {
  background: var(--color-additional-11);
}

.success {
  background: var(--color-success);
}

.gray {
  background: var(--color-neutral-6);
}

.filters {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background: var(--color-neutral-2);
  border-top: 1px solid var(--color-neutral-5);
  border-bottom: 1px solid var(--color-neutral-5);

  > :first-child {
    width: 264px;
    path {
      fill: var(--color-character-secondary-45);
    }
  }
}
