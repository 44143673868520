.container {
  max-width: 600px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .sectionContainer {
    padding-top: 16px;
    padding-bottom: 16px;
    display: grid;
    gap: 12px;

    .section {
      display: grid;
      gap: 8px;
      .list {
        display: grid;
        max-width: 600px;

        .card {
          &Text {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 4px 0;
            gap: 10px;
          }
        }
      }
    }
  }
}
