.wrapper {
  :global {
    .ant-modal-content {
      padding: 0 0 16px !important;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
}

.tag {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  color: var(--color-neutral-1);
  width: fit-content;
  border-radius: 20px;
  gap: 4px;

  svg {
    height: 16px;
    width: 16px;
    color: var(--color-neutral-1);
  }
}

.read {
  background: var(--color-success);
}

.callback {
  background: var(--color-error);
}

.close {
  width: calc(100% - 32px);
  margin: 16px 16px 0;
}

.content {
  border-top: 1px solid var(--color-neutral-4);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.receiver {
  border-top: 1px solid var(--color-neutral-4);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
