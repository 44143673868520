.table {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.meetCoverCell {
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;

  > :nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
      display: flex;
      flex-wrap: wrap;
      row-gap: 4px;
    }
  }

  :global {
    .ant-tag {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 250px;
    }
  }
}
