.wrapper {
  padding: 14px 16px;
  max-width: 600px;

  :global {
    .ant-form-item-control-input {
      min-height: auto !important;
    }

    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}
