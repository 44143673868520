@import 'main';
@import 'mixins';

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

html {
  height: auto;
  min-height: 100%;
  overflow: hidden;
}

body {
  min-height: 100%;
  overflow: hidden;
}

#root {
  height: 100vh;
  min-height: 100%;
}

a {
  :focus {
    background: transparent;
  }
  ::selection {
    background: transparent;
  }
}

:root {
  --color-gradient-primary: linear-gradient(180deg, #006db6 0%, #3ab5f9 100%);
}
