.description {
  svg {
    color: var(--color-primary-6);
  }
}

.button {
  width: max-content;
  padding-inline-start: 0 !important;
}

.container {
  margin-bottom: 24px;
}
