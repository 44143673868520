.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.textArea {
  height: 80px !important;
}
