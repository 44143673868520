.formWrapper {
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;

  > div {
    max-width: 600px;
  }
}

.actionsFooter {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
