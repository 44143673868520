.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 5px;
}

.tooltip {
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover path {
    fill: var(--color-primary-6);
  }
}

.tooltipTitle {
  color: var(--color-neutral-1) !important;
}
