.segmentButton {
  padding: 0px 10px !important;
  border-radius: 6px !important;
}

.segmentedWrapper {
  background-color: var(--color-neutral-3);
  display: flex;
  gap: 4px;
  width: fit-content;
  border-radius: 6px;
}
