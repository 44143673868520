.wrapper {
  border-top: 1px solid var(--color-neutral-5);
  border-bottom: 1px solid var(--color-neutral-5);
  background: var(--color-neutral-2);
  padding: 8px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .input {
    max-width: 264px;
    max-height: 32px;
  }
}
