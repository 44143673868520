.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  margin-bottom: 16px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
  margin-bottom: 28px;
}

.deleteIcon {
  background: var(--color-error);
}

.errorIcon {
  background: var(--color-warning);
}

.successIcon {
  background: var(--color-success);
}
