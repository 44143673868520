.formContainer {
  display: grid;
  gap: 16px;

  .phonesContainer,
  .mailContainer {
    display: grid;
    justify-items: start;
    gap: 8px;

    .textContainer {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .button {
      font-size: 14px;
      color: var(--color-primary-6) !important;
      padding-left: 0px !important;
      padding-top: 16px;
      padding-bottom: 16px;

      &:hover {
        background-color: transparent;
      }
      path {
        fill: var(--color-primary-6);
      }
    }
  }

  .mailContainer input {
    width: 600px;
  }

  .submitButton {
    width: 300px;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
}
