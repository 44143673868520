.container {
  display: flex;
  gap: 32px;
  cursor: pointer;
  padding: 0 16px;
}

.button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
}
