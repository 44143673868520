.fileWrapper {
  display: grid;
  gap: 8px;
  padding: 16px 0;

  .download {
    width: 600px;
    border-radius: 8px;
    border: 1px solid var(--color-neutral-5);
    padding: 12px 16px;
  }

  .icon {
    margin: 12px;
    color: var(--color-primary-6);
    height: 24px;
    width: 24px;
  }

  .signed {
    border: 1px solid var(--color-success) !important;
  }

  .pre {
    white-space: pre-line !important;
  }

  .fileDescription {
    max-width: 448px;
    overflow: hidden;

    > :first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
