.filterActionsWrapper {
  border-bottom: 1px solid var(--color-neutral-5);
  background: var(--color-neutral-2);
  padding: 14px 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  :global {
    .ant-input-affix-wrapper {
      width: 264px;
    }

    .ant-btn {
      height: 32px !important;
    }
  }
}
