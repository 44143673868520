.closeButton {
  flex-shrink: 0;
  max-width: 296px;
  margin-top: 32px;
}

.tabs {
  :global {
    .ant-tabs-content-holder {
      padding-top: 16px;
    }
  }
}
