.container {
  padding-top: 16px;
  display: grid;
  justify-items: center;
}

.close {
  border: none;
  background: transparent;
  justify-self: end;
  cursor: pointer;

  path {
    fill: var(--color-primary-6);
  }
}

.icon {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--color-primary-6);
}

.text {
  display: grid;
  gap: 4px;
  text-align: center;
  margin: 20px 0 16px;
}

.tabs {
  width: 100%;
  background: var(--color-neutral-1);
  border: 1px solid var(--color-neutral-5);
  padding: 0;

  :global {
    .ant-segmented-group {
      background: var(--color-neutral-1);
    }

    .ant-segmented-item {
      height: 40px;
    }

    .ant-segmented-item-selected {
      background: var(--color-primary-6);
    }

    .ant-segmented-item:not(.ant-segmented-item-selected) * {
      color: var(--color-primary-6) !important;
    }

    .ant-segmented-item-selected * {
      color: var(--color-neutral-1) !important;
    }

    .ant-segmented-item:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .ant-segmented-item:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.content {
  margin-top: 16px;
}

.actions {
  margin-top: 32px;
}
