.tree {
  margin: 8px 0 0;

  :global {
    .ant-tree-list-holder-inner {
      border-top: 1px solid var(--color-neutral-3);
    }

    .ant-tree-treenode {
      padding-left: 0px;
    }
  }
}
