.container {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;

  .tag {
    padding: 3px 8px;
    border-radius: 20px;
    background: var(--color-primary-6);
    color: var(--color-neutral-1);

    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}

.button {
  padding: 3px 8px !important;
  height: max-content !important;
  font-size: 12px !important;
}
