.container {
  max-width: 600px;
  margin-top: 16px;

  .title {
    margin-bottom: 24px !important;
  }

  :global {
    .ant-form-item-control-input {
      min-height: auto !important;
    }
  }
}

.check {
  &:last-child {
    margin-bottom: 32px;
  }

  :global {
    .ant-form-item {
      margin-bottom: 12px;
    }
    .ant-checkbox {
      align-self: flex-start;
    }
  }
}
