.search {
  width: 100%;
  padding: 8px 16px;
  background: var(--color-neutral-2);
  border-top: 1px solid var(--color-neutral-5);
  border-bottom: 1px solid var(--color-neutral-5);

  :global {
    .ant-input-affix-wrapper {
      width: 264px;
    }

    .ant-input-prefix {
      color: var(--color-character-secondary-45);
    }
  }
}

.deleteButton {
  border-color: var(--color-error);
  color: var(--color-error);
}

.tabs {
  height: 100%;
  overflow: hidden;

  :global {
    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}
