.container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--color-placeholder-25);

  &.success {
    background-color: var(--color-success);
  }

  &.danger {
    background-color: var(--color-error);
  }

  path {
    fill: var(--color-neutral-1);
  }
}
