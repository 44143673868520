.container {
  margin-top: 24px;
  padding-left: 16px;
  max-width: 750px;
}

.buttons {
  margin-top: 56px;
  margin-left: 16px;
  max-width: 600px;
}
