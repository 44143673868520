.table {
  margin-top: 0;

  :global {
    .ant-table-cell-row-hover {
      cursor: pointer;
    }

    .ant-btn.ant-btn-round.ant-btn-sm {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }
}

.icon {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary-6);

  > svg {
    color: var(--color-primary-1);
  }
}

.loader {
  width: 16px;
  height: 16px;
  border-width: 2px;
}
