.item {
  margin-bottom: 0px !important;

  :global {
    .ant-form-item {
      margin: 0;
      width: 100%;
    }
    .ant-input-group-addon {
      padding: 0px !important;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.buttonCustom {
  padding: 0 !important;
  font-size: 14px !important;
  justify-self: flex-start !important;
  height: max-content !important;
  align-self: flex-start;

  .ant-btn & {
    font-size: 14px !important;
    padding: 0 !important;
    height: max-content !important;
  }
}

.wrapper {
  margin-bottom: 24px !important;
}

.deleteBtn {
  max-height: 30px !important; // Override height here
}
