.wrapper {
  height: 72px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--color-neutral-5);
  background: var(--color-neutral-2);
  padding: 12px 16px;

  > svg {
    flex-shrink: 0;
    color: var(--color-primary-6);
  }

  img {
    flex-shrink: 0;
    width: 85px;
    height: 48px;
    border-radius: 6px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary-6);
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      color: var(--color-primary-5);
    }
  }

  > :nth-child(2) {
    flex-shrink: 1;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    > * {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.icon {
  > :first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 0 16px;

    svg {
      color: var(--color-primary-6);
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 12px;
}
