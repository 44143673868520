.pinnedTableCollapse {
  .pinnedTable {
    tr {
      > :first-child {
        width: 32px;
        padding-left: 16px !important;
      }

      > :last-child {
        width: 80px;
      }
    }

    :global {
      .ant-table-thead {
        visibility: collapse;
      }

      .ant-table-tbody {
        > tr {
          > :nth-child(2) {
            padding: 8px 16px 8px 8px;
          }
        }

        .ant-table-cell {
          &:first-child {
            width: 32px !important;
            padding: 0 0 0 16px !important;

            svg {
              color: var(--color-primary-6);
            }
          }
        }
      }

      .ant-pagination {
        display: none;
      }
    }
  }
}
