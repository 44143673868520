.popup {
  :global {
    .ant-select-tree-switcher {
      display: none;
    }
    .ant-select-tree-checkbox {
      margin-left: 8px !important;
    }

    .ant-select-tree-list-holder {
      padding-top: 8px;
    }
  }
}

.button {
  padding-left: 8px !important;
  padding-top: 0 !important;
  padding-bottom: 8px !important;
  height: 'max-content' !important;
}
