.item {
  margin-bottom: 32px;

  :global {
    .ant-form-item {
      margin: 0;
    }

    .ant-select-selection-item svg {
      display: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .button {
    width: fit-content;
    padding: 0 !important;
    font-size: 14px !important;
    justify-self: flex-start !important;
    height: max-content !important;
  }

  .option {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 12px;
  }
}

.icon {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 8px;

  path {
    fill: var(--color-primary-6);
  }
}
