.container {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 16px;

  input {
    width: 233px;
  }

  .additionalContainer {
    display: flex;
    align-items: center;
    gap: 16px;

    input {
      width: 160px;
    }
  }

  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

  .removeButton {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    color: var(--color-error);

    path {
      fill: var(--color-error);
    }
  }
}
