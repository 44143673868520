.wrapper {
  min-width: 100%;
}

.contentWrapper {
  min-height: 260px;
}

.payslipMonthItem {
  transition: 0.3s;
  border: 1px solid var(--color-neutral-3);
  background: var(--color-neutral-2);
  padding: 8px 16px;
  width: 32%;
  border-radius: 12px;

  &:hover {
    cursor: pointer;
    background: var(--color-neutral-3);
  }
}

.payslipMonthItemSelected {
  transition: 0s;
  background: var(--color-primary-6);
  border-color: var(--color-primary-6);

  &:hover {
    cursor: pointer;
    background: var(--color-primary-6);
  }
}

.payslipMonthItemSelected * {
  color: var(--color-neutral-1);
}

.payslipMonthItemDisabled {
  user-select: none !important;
  cursor: not-allowed !important;
  opacity: 0.5;
}
