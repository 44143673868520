.wrapper {
  display: flex;
  flex-direction: column;

  :global {
    .ant-collapse-expand-icon {
      height: 24px !important;
    }
  }
}

.goBackWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  border-top: 1px solid var(--color-neutral-4);

  button {
    width: 296px;
  }
}

.addCategoryWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  button {
    border: none !important;
  }
}

.collapseTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 570px;
  height: 24px !important;
}

.formWrapper {
  width: 616px;
  padding: 16px;
}

.stepWithInnerSteps {
  :global {
    .ant-collapse-content-box {
      padding: 0 !important;
      border: none !important;
    }
  }
}

.innerStep {
  :global {
    .ant-collapse-header {
      background: var(--color-neutral-2) !important;
    }

    .ant-collapse-content {
      border-left: none !important;
    }
  }
}
