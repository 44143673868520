.content {
  padding: 24px 16px;
  border-top: 1px solid rgb(240, 240, 240);
  display: flex;

  .left {
    width: 60%;
    padding-right: 34px;
  }
  .right {
    width: 40%;

    .title {
      margin-bottom: 16px !important;
    }
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
}

.button {
  width: 300px;
}

.radio {
  margin-top: 30px;
}

.waiting {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  gap: 16px;
}

.radioTitle {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.certificate {
  margin-top: 12px;
  div {
    display: flex;
    padding: 4px;
    border-bottom: 1px solid rgb(240, 240, 240);

    &:first-child {
      border-top: 1px solid rgb(240, 240, 240);
    }

    & > *:first-child {
      width: 200px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}

.form {
  width: 100%;
  max-width: 600px;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.usersButton {
  border: none;
  padding: 0 !important;
}

.actions {
  display: flex;
  gap: 8px;
  margin-top: 34px;
}

.header {
  margin: 12px 16px;

  .buttons {
    display: flex;
    gap: 16px;
  }

  .goBack {
    margin-bottom: 12px;
    padding: 0 !important;
  }
}

.enhance {
  .buttons {
    margin-top: 40px;
    display: flex;
    gap: 8px;
  }
}

.signData {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}

.icon {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--color-warning);
  margin-bottom: 16px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}
