.newsTablePin {
  &:hover {
    background-color: unset !important;

    svg {
      color: var(--color-error);

      &.canPinned {
        color: var(--color-primary-6);
      }

      &.pinned {
        &:hover {
          color: var(--color-error);
        }
      }
    }
  }

  svg {
    color: var(--color-placeholder-25);

    &.pinned {
      color: var(--color-primary-6);
    }
  }
}

.tooltipTitle {
  color: var(--color-neutral-1) !important;
}
