.container {
  display: grid;
  gap: 8px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.area {
  min-height: 84px !important;
}
