.wrapper {
  height: 32px !important;

  :global {
    .ant-form-item-control-input,
    .ant-form-item-control {
      height: 32px !important;
      min-height: 32px !important;
    }

    .ant-input-prefix {
      margin-inline-end: 6px;
    }

    .ant-input-affix-wrapper {
      width: 264px !important;
    }
  }
}
