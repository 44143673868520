.wrapper {
  width: 100%;
  max-width: 600px;
  padding: 14px 16px;
}

.container {
  overflow-y: auto;
}

.fullWidth {
  width: 100%;
}
