.tabs {
  overflow: hidden;

  :global {
    .ant-tabs {
      height: 100%;
      overflow: hidden;
    }

    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}

.padding {
  padding: 16px;
}

.actions {
  width: 632px;
  padding: 0 16px;
}
