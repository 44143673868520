.wrapper {
  :global {
    .ant-tabs {
      overflow: hidden;
    }

    .ant-tabs-tabpane {
      overflow: hidden;
    }

    .ant-tabs-content-holder {
      height: 100%;
      overflow: auto;
    }
  }
}

.actionsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 12px 16px;

  > :first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    button {
      height: 18px !important;
      padding: 0 !important;
      margin-top: 4px;
    }
  }
}

.content {
  width: 616px;
  padding: 16px;
}
