.wrapper {
  width: 100%;
  margin-bottom: 24px;

  > :first-child {
    width: 100%;
    margin-bottom: 0 !important;
  }

  > button {
    padding-inline-start: 0 !important;
  }
}
