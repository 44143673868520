.container {
  margin-top: 8px;
  display: flex;
  gap: 16px;

  .item {
    display: flex;
    gap: 8px;

    path {
      fill: var(--color-character-secondary-45);
    }
  }
}
