.tabs {
  height: 100%;
  overflow: hidden;

  :global {
    .ant-tabs > .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}
