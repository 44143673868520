.download {
  font-size: 12px !important;
}

.container {
  padding: 16px;
}

.filters {
  width: 100%;
  background: var(--color-neutral-2);
  border-top: 1px solid var(--color-neutral-4);
  border-bottom: 1px solid var(--color-neutral-4);
  margin-bottom: 24px;
  padding: 8px 16px;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  :global {
    .ant-select {
      width: 200px;
    }
  }
}
