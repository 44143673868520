.container {
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  gap: 4px;

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tagsContainer {
    display: flex;
    gap: 8px;
    .primaryTag,
    .secondaryTag {
      padding: 3px 8px;
      border-radius: 20px;
    }
    .primaryTag {
      background: var(--color-primary-6) !important;
      color: var(--color-neutral-1);
      border: 1px solid var(--color-primary-6);
    }
    .secondaryTag {
      color: var(--color-primary-6);
      background: var(--color-neutral-1);
      border: 1px solid var(--color-neutral-5);
    }
  }
}
