.container {
  max-width: 420px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .content {
    display: grid;
    gap: 8px;
    margin-bottom: 32px;

    .select {
      width: 100%;
    }

    :global .ant-form-item {
      margin-bottom: 0;
    }
  }

  .buttonContainer {
    display: flex;
    gap: 8px;
  }
}
