.wrapper {
  > div {
    text-align: left;
  }

  img {
    height: 40px;
    width: 40px;
  }
}

.avatar span {
  font-size: 18px !important;
}

.avatarIcon {
  border-radius: 50%;
  background-color: var(--color-primary-6);
  width: 40px;
  height: 40px;

  svg {
    flex-shrink: 0;
    color: var(--color-neutral-1);
  }
}
