.countMembers {
  color: var(--color-primary);
}

.communityCover {
  height: 60px !important;
  width: 60px !important;
  border-radius: 50% !important;
}

.moderatorsButton {
  padding: 0px !important;
  font-weight: 400;
}

.subscribersLink {
  :hover {
    text-decoration: underline;
  }
}
