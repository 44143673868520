.title {
  width: 100%;
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-neutral-4);
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 16px;

  button {
    margin-top: 32px;
  }

  > :first-child {
    background: var(--color-warning);
    border-radius: 50%;
    padding: 8px;
    margin-bottom: 16px;

    svg {
      height: 48px;
      width: 48px;
      color: var(--color-neutral-1);
    }
  }
}
