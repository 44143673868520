.actions {
  margin-top: 32px;
  max-width: 600px;

  > button {
    max-width: 50%;
  }
}

.content {
  border-top: 1px solid red;
}
