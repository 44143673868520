.tag {
  display: flex;
  align-items: center;
  gap: 4px;
  min-height: 24px;
}

.download {
  height: 22px !important;
  padding-inline-start: 0 !important;
  justify-content: flex-start;

  svg {
    height: 16px;
    width: 16px;
  }
}
