@import 'src/styles/mixins.scss';

.wrapper {
  background: var(--color-neutral-3);
  height: 100%;
}

.contentWithSidebar {
  height: 100%;
  padding: 16px 16px 0;
  margin-bottom: -34px;
  display: flex;
  gap: 16px;
  overflow: hidden;

  // QA НАНЫЛИ
  @include tablet {
    flex-direction: column !important;

    > div {
      width: 100% !important;
    }

    > :first-child {
      ::-webkit-scrollbar {
        height: 8px !important;
      }

      max-width: 100% !important;
      height: fit-content !important;
      flex: none !important;

      > div {
        display: flex !important;
        height: fit-content !important;

        > div {
          border-right: 12px solid var(--color-neutral-1) !important;
          border-left: 12px solid var(--color-neutral-1) !important;
          border-bottom: 4px solid transparent !important;
          overflow: hidden !important;
          height: fit-content !important;
          padding: 12px 0 8px;
          align-items: center;

          > :first-child {
            display: none !important;
          }

          > :last-child {
            overflow: hidden !important;
            max-width: 100% !important;
          }
        }
      }
    }
  }
}

.content {
  display: flex;
  overflow: hidden;

  > div {
    overflow: auto;
    z-index: 2;
    border-bottom: 16px solid transparent;
  }
}

.footer {
  background: var(--color-primary-6);
  height: 54px;
}
