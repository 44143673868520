.wrapper {
  display: flex;
  gap: 8px;

  path {
    fill: var(--color-character-secondary-45);
  }

  .infoText {
    display: flex;
    gap: 2px;
  }

  .infoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}
