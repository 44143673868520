.actions {
  max-width: 600px;
}

.longActions {
  max-width: 904px;
}

.closeButton {
  flex-shrink: 0;
  width: 296px;
}
