.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper {
  strong {
    display: flex;
    margin-bottom: 8px;
  }
}

.switchFormItem {
  height: 44px;
  margin-bottom: 16px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.divider {
  span {
    margin-bottom: 24px;
  }
}
