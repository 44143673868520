.pieContainer {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 8px;

  .legendContainer {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: auto;
    grid-auto-flow: column;
    overflow: hidden;
  }
}
