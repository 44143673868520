.tabs {
  height: 100%;
  overflow: hidden;

  :global {
    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }

    .ant-tabs-content-holder {
      padding-left: 16px;
    }
  }
}

.popoverContent {
  position: relative;
  display: flex;
  width: 384px;
  padding: 16px;
  border-radius: 0 20px 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--color-neutral-1);
  text-align: left;

  button {
    top: 8px;
    right: 8px;
    position: absolute;
    border: none;
    background: transparent;
    cursor: pointer;

    svg {
      height: 18px;
      width: 18px;
      color: var(--color-primary-6);
    }
  }
}
