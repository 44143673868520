.actions {
  margin-top: 24px;

  > button {
    width: 300px;
  }
}

.input {
  width: 400px;
}
