.closeButton {
  flex-shrink: 0;
  max-width: 296px;
  margin-top: 32px;
}

.tabs {
  width: 100%;
}

.actions {
  margin-top: 32px;
  max-width: 600px;

  > button {
    max-width: 50%;
  }
}
