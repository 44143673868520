.wrapper {
  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}

.cronWrapper {
  border-left: 2px solid var(--color-primary-6);
  padding-left: 12px;

  :global {
    .ant-picker {
      flex: 1;
    }
  }
}

.cron {
  flex-wrap: nowrap !important;
  :global {
    .ant-select-selection-placeholder {
      color: var(--color-placeholder-25) !important;
    }
    .react-js-cron-field {
      margin-bottom: 0 !important;
      > span {
        margin-left: 0 !important;
      }
    }

    .react-js-cron-minutes {
      > span {
        margin-left: 5px !important;
      }
    }
  }
}

:global {
  .react-js-cron {
    flex-direction: column !important;
    gap: 8px;
  }

  .react-js-cron-custom-select-dropdown.react-js-cron-select-dropdown-months {
    width: 280px !important;
    border-radius: 20px !important;

    .rc-virtual-list-holder-inner {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr) !important;
      gap: 40px 24px !important;
      justify-items: center;
      width: 252px !important;
      margin: 0 auto !important;
      padding: 20px 0 !important;
    }

    .ant-select-item.ant-select-item-option {
      width: 60px !important;
      height: 24px !important;
      min-height: 0px !important;
      align-items: center;
      padding: 0 !important;
      border-radius: 15px !important;

      .ant-select-item-option-content {
        text-align: center !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      &.ant-select-item-option-selected {
        background-color: var(--color-primary-6) !important;

        .ant-select-item-option-content {
          color: var(--color-neutral-1) !important;
        }
      }
    }
  }
  .ant-picker {
    width: 100%;
  }
}

.button {
  height: max-content !important;
  padding: 0 !important;
}
