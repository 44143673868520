.modal {
  max-width: 420px;

  .header {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;

    button {
      background: transparent;
      cursor: pointer;
      border: none;
    }

    svg {
      path {
        fill: var(--color-primary-6);
      }
    }
  }

  .body {
    margin-top: 24px;

    &Texts {
      display: flex;
      justify-content: space-between;
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin-top: 32px;
  }
}
