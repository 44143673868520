.employmentInfoTag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 22px;
  padding: 2px 8px;
  margin: 0;

  span {
    color: var(--color-neutral-1) !important;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  &.outline {
    span {
      color: var(--color-primary-6) !important;
    }
  }
}

.neutral {
  background-color: var(--color-neutral-6) !important;
}
