.maxWidth {
  max-width: 450px;
}

.table {
  margin-top: 0;

  :global {
    .ant-table-cell-row-hover {
      cursor: pointer;

      &:first-child span {
        color: var(--color-primary-6) !important;
      }
    }
  }
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  button {
    font-size: 12px !important;
    border: none !important;
  }
}
