.surveysContainer {
  padding: 80px 0;
  display: grid;
  gap: 16px;
  justify-content: center;
  justify-items: center;

  .text {
    display: grid;
    justify-items: center;
    gap: 4px;
    text-align: center;
  }
}
