.tag {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 5px 10px;

  svg {
    height: 16px;
    width: 16px;
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
}
