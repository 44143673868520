.container {
  padding: 0 16px 16px;
  overflow-y: auto;
}

.info {
  padding-top: 16px;

  svg {
    color: var(--color-error);
  }
}
