.notificationInterval {
  max-width: 175px;
}

.formItem {
  margin-bottom: 0px;
}

.wrapper {
  margin-bottom: 24px;
}
