.content {
  padding: 24px 16px;
  border-top: 1px solid rgb(240, 240, 240);
}

.row {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .column {
    width: 300px;
  }
}

.form {
  max-width: 600px;
}

.actionsFooter {
  display: flex;
  gap: 8px;
}
