.wrapper {
  border: none;
  background: transparent;
  width: 100%;
}

.disabled {
  background-color: var(--color-neutral-3);
}

:global {
  .ql-container {
    display: flex;
  }

  .ql-editor {
    cursor: text;
    min-height: 170px;
    padding: 4px !important;
    width: 100%;

    strong {
      display: inline;
    }
  }
}

.quill {
  > div {
    min-height: 178px;
    border-top: none !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    > div {
      padding: 12px;
    }
  }
}
