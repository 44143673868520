.table {
  :global {
    .ant-table-cell-row-hover {
      cursor: pointer !important;

      &:first-child span {
        color: var(--color-primary-6) !important;
      }
    }
  }
}

.positionItems {
  display: -webkit-box;
}
