.tree {
  margin-top: 8px;
  margin-bottom: 24px;

  :global {
    .ant-tree-treenode {
      height: auto;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 16px;
      margin: 0;
    }
  }
}

.wrapper {
  padding: 8px 16px;
  background-color: var(--color-neutral-2);
}
