.tag {
  display: flex;
  align-items: center;
  gap: 4px;
  min-height: 24px;
}

.table {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
