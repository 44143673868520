.modal {
  overflow: hidden;

  h4 {
    align-self: flex-start;
  }
}

.content {
  max-height: 80vh;
  overflow: auto;

  span {
    display: block;
    margin: 35px 0;
  }
}
