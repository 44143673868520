.wrapper {
  overflow: hidden;

  > div {
    overflow: hidden;
    height: 100%;
  }

  :global {
    .ant-spin-container {
      height: 100% !important;
    }

    .ant-table {
      height: 100% !important;
    }

    .ant-table-container {
      height: 100% !important;
    }

    .ant-table-body {
      max-height: 96% !important;
    }

    .ant-pagination {
      display: none;
    }

    .ant-table-row > td:first-of-type {
      border-left: 2px solid var(--color-primary-6) !important;
    }

    .ant-table-row-level-0 > td:first-of-type {
      border-left: none !important;
    }
  }
}

.plug {
  flex-shrink: 0;
  width: 48px;
}
