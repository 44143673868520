.table {
  overflow: hidden;

  > div {
    overflow: hidden;
    height: 100%;
  }

  :global {
    .ant-spin-container {
      height: calc(100% - 72px) !important;
    }
    .ant-table {
      height: 100% !important;
    }

    .ant-table-container {
      height: 100% !important;
    }
    .ant-table-body {
      max-height: 96% !important;
    }

    .ant-table-tbody .ant-table-cell {
      padding: 0;
    }

    .ant-table-tbody > tr > td {
      border-bottom: none;
    }
  }
}

.tableHeaderContainer {
  display: grid;
  grid-template-columns: 425px 1fr;
  justify-items: start;
  span {
    padding-left: 16px;
  }
}

.collapse {
  :global {
    .ant-collapse-content-box {
      background: var(--color-neutral-1) !important;
    }
  }
}
