.tabs {
  margin-top: 12px;
  :global {
    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-nav-list {
      padding-left: 16px;
      padding-right: 16px;
      margin-left: 0;
    }
  }
}

.wrapper {
  > :nth-child(2) {
    height: 100%;
    overflow: hidden;
  }

  :global {
    .ant-tabs {
      height: 100%;
      overflow: hidden;
    }

    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}
