.wrapper {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  textarea {
    height: 64px !important;
    resize: none;
  }

  strong {
    display: flex;
    margin-bottom: 8px;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}

.radio {
  width: 100%;
  margin-top: 8px;

  :global {
    .ant-radio-button-wrapper {
      color: var(--color-primary-6);
    }

    .ant-radio-button-wrapper-disabled {
      color: var(--color-character-secondary-45) !important;
      background: var(--color-neutral-3) !important;

      &.ant-radio-button-wrapper-checked {
        background: var(--color-primary-6) !important;
        color: var(--color-neutral-1) !important;
      }
    }
  }

  label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
