.wrapper {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  textarea {
    max-height: 64px !important;
    resize: none;
  }

  strong {
    display: flex;
    margin-bottom: 8px;
  }
}

.option {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > :first-child {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      color: var(--color-primary-6);
      height: 32px;
      width: 32px;
    }
  }
}
