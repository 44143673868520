.container {
  display: grid;
  justify-items: center;

  .icon {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  .text {
    display: grid;
    gap: 4px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 32px;
  }

  &.warning {
    .icon {
      background-color: var(--color-warning);
    }

    .button {
      color: var(--color-error) !important;
      border-color: var(--color-error) !important;
    }
  }

  &.danger {
    .icon {
      background-color: var(--color-error);
    }

    .button {
      color: var(--color-error) !important;
      border-color: var(--color-error) !important;
    }
  }

  &.success {
    .icon {
      background-color: var(--color-success);
    }
  }
}
