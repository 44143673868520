.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  max-width: 600px;
  padding: 0 16px 24px;

  > * {
    width: 100%;
  }
}
