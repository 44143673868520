.wrapper {
  padding: 0 16px 16px;
  max-width: 616px;
}

.infoContainer {
  border: 1px solid var(--color-neutral-5);
  background: var(--color-neutral-2);
  border-radius: 16px;
  padding: 16px;
}

.infoContainerRow {
  > div:first-child {
    width: 50%;
  }
}
