.modal {
  max-width: 420px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .close {
      border: none;
      outline: none;
      cursor: pointer;
      background-color: transparent;

      svg {
        color: var(--color-primary-6);
      }
    }
  }

  .content {
    margin: 16px 0 32px;

    .select {
      width: 100%;
    }

    :global .ant-form-item {
      margin-bottom: 0;
    }
  }

  .buttonContainer {
    display: flex;
    gap: 8px;
  }
}
