.form {
  height: 100%;
}

.tabs {
  height: 100%;
  overflow: hidden;

  :global {
    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}

.container {
  max-width: 600px;
  margin-left: 16px;
  margin-top: 16px;
}

.wrapper {
  overflow-y: auto;
}
