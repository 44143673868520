.formWrapper {
  margin-top: 32px;

  :global {
    .ant-form-item-label {
      > label {
        color: var(--color-primary-6);
      }
    }
  }
}
