.wrapper {
  :global {
    .ant-popover-inner-content {
      border-radius: 4px;
      background: var(--color-character-title-85) !important;
      color: var(--color-neutral-1) !important;
      padding: 3px 8px !important;
      max-width: 200px;
    }
  }
}
