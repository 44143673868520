.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 536px;
}

.dangerButton {
  color: var(--color-error) !important;
  border-color: var(--color-error) !important;
}
