.wrapper {
  h4 {
    font-weight: 500;
  }

  :global {
    .ant-collapse-content-box {
      overflow-y: hidden;
      padding: 0 !important;
    }

    .ant-collapse-item-active .ant-collapse-header {
      padding: 16px 16px 8px;
      box-shadow: 0px -1px 0px 0px transparent inset;
    }

    .ant-collapse-content .ant-collapse-content-box {
      background: var(--color-neutral-1);

      p {
        background: transparent;
      }
    }

    .ant-collapse-item {
      border-bottom: 1px solid var(--color-neutral-3);

      &:last-child {
        border-bottom: 1px solid var(--color-neutral-3);
      }
    }

    .ant-collapse-header {
      border-bottom: 1px solid transparent !important;
    }
  }
}
