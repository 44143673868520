.table {
  margin-top: 0;

  :global {
    .ant-table-cell-row-hover {
      cursor: pointer;
    }
  }
}

.tooltip {
  :global {
    .ant-tooltip-content {
      width: 160px;
    }
  }
}

.tooltipTitle {
  width: 140px;
  color: var(--color-neutral-1);
}
