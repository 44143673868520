.container {
  max-width: 600px;
  padding-left: 16px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .sectionContainer {
    padding-top: 8px;
    padding-bottom: 16px;
    display: grid;
    gap: 12px;

    .section {
      display: grid;
      gap: 8px;
      .list {
        display: grid;
        max-width: 600px;

        .card {
          &Text {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 4px 0;
            gap: 10px;
          }
        }
      }
    }
  }
}

.multiSelectWrapper {
  padding: 16px 0px 0px 16px !important;
  max-width: 600px;
}

.collapse {
  background-color: var(--color-neutral-1) !important;
  :global {
    .ant-collapse-content-box {
      background: var(--color-neutral-1) !important;
    }

    .ant-collapse-expand-icon {
      height: 20px;
      width: 20px;
    }

    .ant-collapse-header {
      padding: 0px !important;
      border-bottom: none !important;
    }

    .ant-collapse-item {
      border-bottom: none !important;
    }

    .ant-collapse-item-active .ant-collapse-header {
      border-bottom: none !important;
    }

    .ant-collapse-item > .ant-collapse-header {
      box-shadow: none !important;
    }
  }
}

.wrapper {
  margin-left: -16px;
}
