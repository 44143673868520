.wrapper {
  img {
    width: 48px;
    height: 48px;
  }

  > div {
    text-align: left;
  }
}

.employmentInfo {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;

  > :first-child {
    display: flex;
    align-items: center;
    gap: 10px;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
    }
  }

  img {
    height: 48px;
    width: 48px;
  }
}
