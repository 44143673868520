.container {
  padding: 0 16px;
}

.filters {
  width: 100%;
  background: var(--color-neutral-2);
  border-top: 1px solid var(--color-neutral-4);
  border-bottom: 1px solid var(--color-neutral-4);
  margin-bottom: 24px;
  padding: 8px 16px;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  :global {
    .ant-select {
      width: 200px;
    }
  }
}

.pieTextContainer {
  display: grid;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 4px;
  height: max-content;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 2px 4px;
  width: max-content;

  .legendText {
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.circle {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  > span {
    color: var(--color-neutral-1);
  }
}

.removeSegments {
  position: fixed;
  right: 10px;
  bottom: 10px;

  width: 40px;
  height: 40px;
  border-radius: 8px !important;
}

.selected {
  border-color: var(--color-primary-6);
}

.tooltip {
  display: grid;
  gap: 8px;
}

.tooltipText {
  color: var(--color-neutral-1) !important;
}

.download {
  font-size: 12px !important;
}
