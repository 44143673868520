.wrapper {
  display: flex;
  gap: 2px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-color: var(--color-neutral-4) !important;

  button {
    svg {
      border-radius: 4px;
      height: 20px;
      width: 20px;
      color: var(--color-character-secondary-45);
    }

    &:hover {
      svg {
        background: var(--color-primary-5);
        color: var(--color-neutral-1);
      }
    }
  }

  > * {
    margin-right: 0 !important;
  }

  > div {
    display: flex;
    gap: 2px;
  }
}

.disabled {
  background-color: var(--color-neutral-3);

  button {
    &:hover {
      cursor: not-allowed;

      svg {
        background: initial;
        color: var(--color-character-secondary-45);
      }
    }
  }
}

:global {
  .ql-active {
    svg {
      background: var(--color-primary-6);
      color: var(--color-neutral-1) !important;
    }
  }
}
