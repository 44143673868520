.tags {
  display: flex;
  gap: 8px;
}

.buttons {
  display: flex;
  gap: 16px;
}

.goBack {
  margin-bottom: 12px;
  padding: 0 !important;
}

.stats {
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 25px;

  svg {
    height: 16px;
    width: 16px;
  }
}
