.maxWidth {
  max-width: 450px;
}

.table {
  :global {
    .ant-table-cell-row-hover {
      cursor: pointer;

      &:first-child span {
        color: var(--color-primary-6) !important;
      }
    }
  }
}
