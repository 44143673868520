.modal {
  h4 {
    align-self: flex-start;
    margin-bottom: 20px !important;
  }
}

.wrapper {
  padding: 8px 12px;
  border-radius: 12px;

  &:hover {
    background-color: var(--color-neutral-3);
  }
}

.container {
  width: 100%;
}
