.wrapper {
  :global {
    .ant-modal-content {
      position: relative;
      height: fit-content !important;
    }
  }
}

.actions {
  display: flex;
  gap: 16px;

  button {
    width: 100%;
  }
}

.close {
  height: 18px;
  width: 18px;
  color: var(--color-primary-6);
}

.radio {
  width: 100%;
  margin-top: 24px;

  label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.invisible {
  height: 0;
  width: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
}
