.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoBlue {
  margin-top: 48px;
  height: 40px;
  margin-bottom: 100px;
  flex-shrink: 0;
}
