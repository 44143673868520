.wrapper {
  background: var(--color-neutral-1);
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  align-items: center;
  gap: 56px;
  padding-right: 24px;

  button {
    color: var(--color-primary-6);
  }
}

.dropdownButton {
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  gap: 4px;

  svg {
    height: 16px;
    width: 16px;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    color: var(--color-primary-6);
  }
}

.overlay {
  :global {
    .ant-dropdown-menu-item-disabled {
      background-color: var(--color-neutral-2) !important;

      &:hover {
        border-radius: 12px;
        cursor: default !important;
      }
    }

    .ant-dropdown-menu-item {
      margin: 10px 12px !important;
      border-radius: 12px !important;

      &:hover {
        background-color: var(--color-neutral-3) !important;
      }
    }

    .ant-dropdown-menu {
      width: 400px;
      border-radius: 20px;
    }
  }
}

.container {
  cursor: pointer;
  user-select: none;

  img {
    width: 40px;
    height: 40px;
  }

  svg {
    color: var(--color-primary-6);
  }
}

.textContainer {
  max-width: 276px;

  span,
  h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.logoutButton {
  svg {
    color: var(--color-primary-6);
  }
}

li.person:global(.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled) {
  background-color: transparent !important;
}
