.title {
  padding-bottom: 24px !important;
}

.actions {
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.preview {
  > :first-child {
    > svg {
      color: var(--color-error);
    }
  }
}

.dragger {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 16px 0 32px;

  > :first-child {
    height: 24px;
    width: 24px;
    color: var(--color-primary-6);
  }

  > :last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
}

.uploadWrapper {
  :global {
    .ant-upload .ant-upload-btn {
      padding: 12px 0 !important;
    }
  }
}
