.container {
  display: flex;
  align-items: center;
  gap: 5px;
  width: max-content;
  padding: 3px 8px;
  border-radius: 20px;

  > span {
    color: var(--color-neutral-1);
  }

  &.success {
    background: var(--color-success);
  }

  &.danger {
    background: var(--color-error);
  }

  &.warning {
    background: var(--color-warning);
  }
  &.continue {
    background-color: var(--color-additional-11);
  }

  &.neutral {
    background-color: var(--color-neutral-6);
  }

  :global {
    .ant-typography {
      word-break: normal;
      display: inline-block;
    }
  }

  path {
    fill: var(--color-neutral-1);
  }
}
