.wrapper {
  > :nth-child(2) {
    height: 100%;
    overflow: hidden;
  }

  :global {
    .ant-tabs {
      height: 100%;
      overflow: hidden;
    }

    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}

.mainForm {
  padding: 16px;
}

.actions {
  width: 936px;
  padding: 0 16px;
}
