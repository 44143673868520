.formWrapper {
  padding: 24px 16px 0;
}

.title {
  padding: 0 16px 10px;
}
.tabs {
  height: 100%;
  overflow: hidden;

  :global {
    .ant-tabs > .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-content-holder {
      overflow: auto;
    }
  }
}
