.content {
  padding: 16px;
}

.tag {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
  padding: 12px 10px;
}

.info {
  margin-bottom: 24px;
}

.divider {
  padding-bottom: 16px;
}

.history {
  padding-right: 48px;
}

.row > div:first-child {
  width: 200px;
}

.rowEmail {
  width: 200px;
}
