.wrapper {
  display: flex;
  gap: 8px;
  margin-top: 40px;
  padding: 16px;

  button {
    width: 296px;
  }
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  textarea {
    resize: none;
    height: 60px !important;
  }

  :global {
    .ant-form-item {
      width: 100%;
    }
  }
}

.iconWrapper {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--color-neutral-1);
    height: 48px;
    width: 48px;
  }
}

.actions {
  width: 100%;
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.fullWidth {
  width: 100%;
}

.description {
  padding: 4px 0 16px;
}

.row {
  display: flex;
  gap: 10px;
  width: 100%;

  > * {
    width: 100%;
  }

  input {
    height: 40px;
  }

  :global {
    .ant-form-item-control-input {
      min-height: 40px;
      height: 40px;
    }
    .ant-input-wrapper {
      height: 40px;
    }
  }
}

.primary {
  background: var(--color-primary-6);
}

.warning {
  background: var(--color-warning);
}

.success {
  background: var(--color-success);
}

.danger {
  background: var(--color-error);
}
