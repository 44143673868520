.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--color-neutral-2);
  border: 1px solid var(--color-neutral-5);
  border-radius: 8px;
  cursor: pointer;
  padding: 16px;

  &:hover {
    border-color: var(--color-primary-6);
  }

  .remove {
    border: none;
    background-color: transparent;
    cursor: pointer;
    path {
      fill: var(--color-primary-6);
    }
  }
}

.errorStatus {
  border: 1px solid var(--color-error) !important;
}
