.content {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 24px;

  > :first-child {
    height: 24px;
    width: 24px;
    color: var(--color-primary-6);
  }

  .textContainer {
    display: grid;
    width: 400px;

    & > span {
      width: 400px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      text-align: start;
    }
  }

  &.error {
    > :first-child {
      color: var(--color-error);
    }
  }
}
