.wrapper {
  width: 600px;
  padding-top: 16px;
  border-bottom: 1px solid var(--color-neutral-3);

  textarea {
    height: 60px !important;
    resize: none;
  }

  strong {
    display: flex;
  }

  .formItemWithLabel {
    margin-bottom: 4px;
  }

  .formItemLabel {
    margin-top: 16px;
  }

  .organization {
    margin-top: 24px;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.marginBottom {
  margin-bottom: 16px;
}

.document {
  > div {
    flex: 1;
  }

  :global {
    .ant-picker {
      width: 100%;
    }
  }
}
