.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  :global {
    .ant-form-item {
      margin: 0;
    }
  }
}
