.wrapper {
  :global {
    .ant-form-item-explain-error {
      padding-left: 16px !important;
    }
  }
}

.tree {
  margin: 8px 0 0;

  :global {
    .ant-tree-list-holder-inner {
      border-top: 1px solid var(--color-neutral-3);
    }

    .ant-tree-treenode {
      height: auto;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 16px;
      margin: 0;
    }
  }
}

.loader {
  height: 366px;
}
