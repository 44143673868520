.container {
  margin-top: 28px;
  padding: 0 16px 16px;
  max-width: 600px;

  .icon {
    color: var(--color-character-secondary-45);
  }

  :global {
    .ant-progress {
      margin: 0 !important;
    }
  }
}
