.tag {
  display: flex;
  gap: 2px;
  align-items: center;
  margin: 0;
  padding: 5px 10px;
  height: 30px;

  > span {
    color: inherit;
  }

  &.published {
    background-color: var(--color-success);
  }

  &.notPublished {
    background-color: var(--color-neutral-6);
  }
}
