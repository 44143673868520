.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  textarea {
    height: 60px !important;
    resize: none;
  }

  :global {
    .ant-form-item {
      margin-top: 8px !important;
      margin-bottom: 0 !important;
    }
  }
}

.submitButton {
  width: 286px;
}

.row {
  display: flex;
  align-items: flex-end;
  gap: 8px;

  button {
    height: 32px !important;
  }
}

.addPhone {
  margin-top: 8px;
  font-size: 14px !important;
  height: 22px !important;
  border: none !important;
  padding: 0 !important;
}

.optional {
  margin-left: 4px;
}
