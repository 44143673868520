.additional {
  margin-top: 56px;
  margin-bottom: 56px;
}

.selectWithoutMargin {
  margin-bottom: 0;
}

.nameContainer {
  margin-bottom: 32px;
}

.receiversContainer {
  margin-bottom: 0;
}

.receiversLink {
  margin-top: 8px;
  padding: 0 !important;
}
