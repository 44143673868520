.loaderModal {
  width: 100%;
  padding: 20px 0 4px;
  gap: 16px;
}

.loader {
  width: 80px;
  height: 80px;
}
