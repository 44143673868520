.title {
  display: flex;
  justify-content: space-between;
  margin: 24px 0 4px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox {
  margin: 16px 0 32px;
}
