@import 'styles/mixins.scss';

.tableWrapper {
  overflow: auto;
  height: 100%;
}

.destination {
  @include ellipsis-text;
  -webkit-line-clamp: 2;
}
