.wrapper {
  width: 100%;
  margin: 16px 0 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.iconRight {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: none;

  svg {
    color: var(--color-primary-6);
  }
}
