.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, var(--color-primary-6) 0%, var(--color-primary-5) 100%);

  .line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .card {
    z-index: 2;
    display: grid;
    justify-items: center;
    gap: 54px;
    width: 480px;
    padding: 40px;
    text-align: center;
  }
}
