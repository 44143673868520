.actionsFooter {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.formWrapper {
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 800px;
}

.formInfo {
  margin-top: 4px;

  svg {
    height: 18px;
    width: 18px;
    color: var(--color-primary-6);
  }
}
