.wrapper {
  textarea {
    height: 60px !important;
    resize: none;
  }

  strong {
    display: flex;
    margin-bottom: 8px;
  }
}
