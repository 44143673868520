.tabs {
  overflow: hidden;

  :global {
    .ant-tabs-content {
      height: 100% !important;
      overflow: auto;
    }
  }
}
