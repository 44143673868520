.actionsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  > :first-child {
    margin: 12px 0;
  }

  > :last-child {
    margin: 12px 16px;
  }
}
.formWrapper {
  padding: 0 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 600px;
}

.actionsFooter {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  max-width: 600px;
  padding: 0 16px 24px;
}

.tableTitle {
  padding: 16px;
}
