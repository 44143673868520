@mixin phone {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

@mixin ellipsis-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
