.content {
  padding: 24px 16px;
  border-top: 1px solid rgb(240, 240, 240);
}

.form {
  width: 100%;
  max-width: 600px;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.usersButton {
  padding-inline-start: 0 !important;
}

.actions {
  display: flex;
  gap: 8px;
  margin-top: 34px;
}
