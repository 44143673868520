.wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
}

.size24 {
  height: 24px;

  svg {
    height: 14px;
    width: 14px;
  }
}

.size30 {
  height: 30px;

  svg {
    height: 16px;
    width: 16px;
  }
}
