.overflow {
  overflow-y: auto;
}

.headerWrapper {
  padding: 12px 16px;
}

.content {
  display: flex;
  gap: 32px;
  padding: 24px 16px 0;

  > :first-child {
    width: 600px;
  }
}
