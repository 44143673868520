.wrapper {
  display: flex;
  align-items: center;

  button {
    border: none !important;

    svg {
      height: 16px;
      width: 16px;
    }
  }
}
