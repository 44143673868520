.table {
  :global {
    .ant-table-cell-row-hover {
      cursor: pointer;

      &:first-child span {
        color: var(--color-primary-6) !important;
      }
    }
  }
}

.loader {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
