.wrapper {
  width: 100%;
  max-width: 600px;
  height: 100%;

  textarea {
    height: 64px !important;
    resize: none;
  }

  strong {
    display: flex;
    margin-bottom: 8px;
  }
}
