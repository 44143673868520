.tree {
  margin-bottom: 24px;

  :global {
    .ant-tree-switcher {
      display: none !important;
    }

    .ant-tree-title h5 {
      padding-top: 4px !important;
    }

    div.ant-tree-treenode {
      padding-left: 0 !important;

      &:has(.ant-tree-indent-unit) {
        border-left: none !important;
        background: transparent !important;
      }

      &:not(.ant-tree-treenode-leaf-last) {
        padding-bottom: 0 !important;
      }

      &.ant-tree-treenode-switcher-open:not(:first-child) {
        margin-top: 24px !important;
      }

      &.ant-tree-treenode-leaf-last {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      .ant-tree-node-content-wrapper {
        pointer-events: none;
      }

      .ant-tree-checkbox,
      .ant-typography,
      input {
        pointer-events: auto;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
