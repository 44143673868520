.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;

  > span {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  svg {
    height: 18px;
    width: 18px;
    color: var(--color-character-secondary-45);
  }
}
