.actionsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid var(--color-neutral-3);
  width: 100%;
  padding: 12px 16px;

  > :first-child {
    button {
      height: 18px !important;
      padding: 0 !important;
      margin-top: 4px;
      margin-bottom: 8px;
    }
  }
}

.content {
  width: 616px;
  padding: 16px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
}
