.grid {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  align-items: center;

  :global {
    .ant-radio-wrapper {
      margin: 0;
      border-radius: 12px;
      span {
        padding: 0;
        width: max-content;
        height: 48px;
      }

      &:hover {
        background-color: var(--color-neutral-3);
      }

      &.ant-radio-wrapper-checked {
        background-color: var(--color-primary-6);
        path {
          fill: var(--color-neutral-1);
        }
      }
      &::after {
        display: none;
      }
    }
    .ant-radio {
      display: none;
    }
  }

  svg {
    margin: 14px;
    cursor: pointer;

    path {
      fill: var(--color-primary-6);
    }
  }
}

.search {
  :global {
    .ant-form-item {
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }
}
