.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: max-content;
  padding: 13px 0px 13px 32px;

  svg {
    height: 29px;
    width: 100px;
  }
}
