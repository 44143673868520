.main {
  border-top: 1px solid var(--color-neutral-3);
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: 24px 16px 16px;
  gap: 0 32px;
}

.actions {
  margin-top: 32px;
  max-width: 600px;

  > button {
    max-width: 50%;
  }
}
