.selectWrapper {
  display: flex;
  flex-direction: column;
  margin: 24px 0 32px;
  gap: 6px;

  > * {
    width: 100%;
  }
}

.actions {
  display: flex;
  gap: 8px;
}
