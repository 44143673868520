.main {
  border-top: 1px solid var(--color-neutral-3);
  display: grid;
  grid-template-columns: 1fr;
  padding: 24px 16px 16px;
  gap: 0 32px;

  .left {
    width: 600px;

    &Employee {
      display: grid;
      gap: 8px;

      &Data {
        display: grid;
      }
    }

    &Statement {
      display: grid;
      gap: 4px;
      margin-top: 24px;
    }

    &Tabs {
      margin-top: 24px;

      :global {
        .ant-tabs-nav-list {
          margin-left: 0 !important;
        }
      }
    }
  }

  .right {
    display: grid;
    gap: 16px;
    grid-template-rows: max-content;
  }

  .closeButton {
    max-width: 296px;
  }
}

.backButton {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  gap: 10px;

  path {
    fill: var(--color-primary-6);
  }
}

.file {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fileWrapper {
  display: grid;
  gap: 16px;
  padding: 16px 0;

  .download {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    border-radius: 8px;
    border: 1px solid var(--color-neutral-5);
    gap: 12px;
    padding: 12px 16px;

    > div {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &.signed {
      border: 1px solid var(--color-success) !important;
    }
  }

  .icon {
    margin: 12px;
    color: var(--color-primary-6);
    height: 24px;
    width: 24px;
  }

  .downloadButton {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .fileDescription {
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 448px;
    overflow: hidden;

    > :first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.personsContainer {
  margin-top: 20px;
  margin-bottom: 44px;
  display: grid;
  gap: 16px;
  max-width: 600px;
}

.closeButton {
  max-width: 296px;
  margin-top: 32px;
}

.tabs {
  width: 100%;
}

.replacing {
  background: var(--color-neutral-2);
  border-radius: 12px;
  padding: 12px;

  svg {
    height: 18px;
    color: var(--color-primary-6);
  }
}
