.fullWidth {
  width: 100% !important;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  :global {
    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }
}
