.copy {
  all: unset;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;

  outline: 4px solid transparent;
  outline-offset: 4px;
  border-radius: 20px;

  &:focus-visible {
    outline: 1px solid var(--color-primary-4);
  }

  svg {
    color: var(--color-primary-6);
    height: 14px;
    width: 14px;
  }
}
