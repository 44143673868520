.inviteSubscribeUser {
  max-width: 400px;
  width: 100%;
}

.userDetails {
  max-width: calc(100% - 55px);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
