.wrapper {
  :global {
    .ant-tabs {
      height: 100%;
      overflow: hidden;
    }

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-tabpane {
      height: 100%;
      overflow: auto;
    }
  }
}

.header {
  padding: 0 16px 24px;
}

.formWrapper {
  padding: 0 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 600px;
}

.tableTitle {
  padding: 16px;
}
