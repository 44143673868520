.container {
  display: flex;
  align-items: center;
  gap: 5px;
  width: max-content;
  border-radius: 20px;
  height: 30px;
  margin-right: 0 !important;

  > span {
    color: var(--color-neutral-1);
  }

  &.default {
    height: 30px;
  }

  &.small {
    height: 24px;
  }
}
