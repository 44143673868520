.container {
  max-width: 600px;
  margin-left: 16px;
  margin-top: 16px;

  :global {
    .ant-form-item-label {
      width: 100%;

      label {
        width: 100%;

        &::after {
          display: none;
        }

        .ant-flex {
          width: 100%;
        }
      }
    }
  }
}
