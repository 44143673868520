.container {
  .containerItem {
    display: grid;
    grid-template-columns: 425px 1fr;
    border-bottom: 1px solid var(--color-neutral-4);

    &:last-child {
      border-bottom: none;
    }

    .name {
      display: flex;
      align-items: center;
      padding-left: 48px;
    }

    .contactsContainer {
      padding: 16px;
      display: grid;
      gap: 16px;

      .phoneContainer,
      .mailContainer {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        gap: 10px;

        path {
          fill: var(--color-primary-6);
        }

        .phonesContainer {
          display: flex;
          gap: 16px;
        }
      }
    }
  }
}
