.container {
  margin-left: -16px;
  padding-top: 16px;
  padding-bottom: 32px;

  :global {
    .ant-collapse-header {
      padding: 8px 18px !important;
      align-items: center !important;
    }
  }
}
