.avatarContainer {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 10px;
  align-items: center;

  .info {
    display: grid;
  }

  .avatar span {
    font-size: 18px !important;
  }

  .position {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.container {
  display: flex;
  justify-content: space-between;

  .avatarBadge {
    position: relative;

    .badgeBackgound {
      position: absolute;
      left: -2px;
      top: -2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.success {
        background-color: var(--color-success);
      }

      &.warning {
        background-color: var(--color-warning);
      }

      &.error {
        background-color: var(--color-error);
      }

      .badge path {
        fill: var(--color-neutral-1);
      }
    }
  }
}

.popoverContent {
  padding: 2px 8px;
  color: var(--color-neutral-1) !important;
}
